import React, { useEffect, useState } from "react"
import { ArticleItem } from "../../components/ArticleItem"
import { Page } from "../../components/Page"

type ListDocument = {
  slug: string
  wallet: string
  createTime: number
  updateTime: number
  amountUpvotes: number
  title: string
  writer?: { twitterHandle: string; picture: string; label: string }
}

const searchDocuments = async (query: string) => {
  const resultRes = await fetch(
    `${process.env.GATSBY_BACKEND_ENDPOINT}searchDocuments/${query}`
  )
  return resultRes.json()
}

export default function Home({ params }: { params: { query: string } }) {
  const [documents, setDocuments] = useState([] as ListDocument[])

  useEffect(() => {
    searchDocuments(params.query).then(setDocuments)
  }, [params.query])

  return (
    <Page>
      <h3>Search results for: {params.query}</h3>
      <div className="threeToOneColumnCount">
        {documents.map(document => (
          <div key={document.slug}>
            <ArticleItem
              to={`/d/${document.slug}`}
              label={document.writer?.label}
              writer={document.writer?.twitterHandle || document.wallet}
              title={document.title}
              amountUpvotes={document.amountUpvotes}
              picture={document.writer?.picture}
            />
          </div>
        ))}
      </div>
    </Page>
  )
}
